// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"

// COMPONENT FUNCTION
const FiberglassPartsPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      fiberglassParts: file(relativePath: { eq: "fiberglass-parts.jpg" }) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Fiberglass Parts">
      <PageTitle title="FIBERGLASS PARTS" />
      <ImageBlock>
        <ImageWithCaption
          fluid={data.fiberglassParts.childImageSharp.fluid}
          caption="Fiberglass Parts"
          maxWidth={600}
        />
      </ImageBlock>
      <Typography paragraph>
        Bay Sails Marine offers a wide selection of fiberglass parts including
        pilot houses, consoles, leaning posts, radio boxes, t-tops, splashwells
        and more.
      </Typography>
    </Layout>
  )
}

export default FiberglassPartsPage
